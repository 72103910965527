import { getRoute, Route } from 'pmt-modules/routing'
import { tr } from 'pmt-modules/i18n'

import { Security, PimPermissions } from 'pmt-modules/bo'

export const routesPimv2Definitions = {
  'referential-settings-edit': new Route('referential-settings-edit', '/pim/referential/settings'),
  // Menus
  'menu-detail': new Route('menu-detail', '/pim/menu/:menuId'),
  'menu-list': new Route('menu-list', '/pim/menus'),
  'menu-create': new Route('menu-create', '/pim/menus/create'),

  // Revisions
  'menu-revision-list': new Route('menu-revision-list', '/pim/menu/:menuId/revisions'),
  'revision-detail': new Route('revision-detail', '/pim/menu/:menuId/revisions/:revisionId'),

  // Syncs
  'menu-sync-list': new Route('menu-sync-list', '/pim/menu/:menuId/syncs'),
  'menu-sync-detail': new Route('menu-sync-detail', '/pim/menu/:menuId/sync/:syncId'),
  'menu-sync-start': new Route('menu-sync-start', '/pim/menu/:menuId/sync-start'),
  'menu-sync-step-detail': new Route(
    'menu-sync-step-detail',
    '/pim/menu/:menuId/sync/:syncId/step/:stepId'
  ),

  'menu-sync-workflow-run-list': new Route(
    'menu-sync-workflow-run-list',
    '/pim/menu/:menuId/sync-workflow-runs'
  ),
  'menu-sync-workflow-run-detail': new Route(
    'menu-sync-workflow-run-detail',
    '/pim/menu/:menuId/sync-workflow-runs/:runId'
  ),
  'menu-sync-workflow-configuration-list': new Route(
    'menu-sync-workflow-configuration-list',
    '/pim/menu/:menuId/sync-workflow-configurations'
  ),
  'menu-sync-workflow-configuration-create': new Route(
    'menu-sync-workflow-configuration-create',
    '/pim/menu/:menuId/sync-workflow-configurations/create'
  ),

  'menu-sync-workflow-configuration-edit': new Route(
    'menu-sync-workflow-configuration-edit',
    '/pim/menu/:menuId/sync-workflow-configurations/:configurationId/edit'
  ),

  // Deployments
  'menu-deployment-list': new Route('menu-deployment-list', '/pim/menu/:menuId/deployments'),
  'revision-deployment-list': new Route(
    'revision-deployment-list',
    '/pim/menu/:menuId/revisions/:revisionId/deployments'
  ),

  // Items

  'revision-item-list': new Route(
    'revision-item-list',
    '/pim/menu/:menuId/revisions/:revisionId/items'
  ),
  'revision-item-create': new Route(
    'revision-item-create',
    '/pim/menu/:menuId/revisions/:revisionId/items/create'
  ),
  'revision-item-edit': new Route(
    'revision-item-edit',
    '/pim/menu/:menuId/revisions/:revisionId/items/:itemId/edit'
  ),
  'revision-item-linked-parts': new Route(
    'revision-item-linked-parts',
    '/pim/menu/:menuId/revisions/:revisionId/items/:itemId/linked-parts'
  ),
  'revision-item-used-by-parts': new Route(
    'revision-item-used-by-parts',
    '/pim/menu/:menuId/revisions/:revisionId/items/:itemId/used-by-parts'
  ),
  'revision-item-used-by-categories': new Route(
    'revision-item-used-by-categories',
    '/pim/menu/:menuId/revisions/:revisionId/items/:itemId/used-by-categories'
  ),
  'revision-item-level-configuration': new Route(
    'revision-item-level-configuration',
    '/pim/menu/:menuId/revisions/:revisionId/items/:itemId/level-configurations/:parentItemPartId'
  ),

  // Item parts

  'revision-item-part-list': new Route(
    'revision-item-part-list',
    '/pim/menu/:menuId/revisions/:revisionId/item-parts'
  ),
  'revision-item-part-create': new Route(
    'revision-item-part-create',
    '/pim/menu/:menuId/revisions/:revisionId/item-parts/create'
  ),
  'revision-item-part-edit': new Route(
    'revision-item-part-edit',
    '/pim/menu/:menuId/revisions/:revisionId/item-parts/:itemPartId/edit'
  ),
  'revision-item-part-linked-items': new Route(
    'revision-item-part-linked-items',
    '/pim/menu/:menuId/revisions/:revisionId/item-parts/:itemPartId/linked-items'
  ),
  'revision-item-part-used-by-items': new Route(
    'revision-item-part-used-by-items',
    '/pim/menu/:menuId/revisions/:revisionId/item-parts/:itemPartId/used-by-items'
  ),
  'revision-item-part-level-configuration': new Route(
    'revision-item-part-level-configuration',
    '/pim/menu/:menuId/revisions/:revisionId/item-parts/:itemPartId/level-configurations/:parentItemId'
  ),

  // Hierarchies

  'revision-hierarchy-list': new Route(
    'revision-hierarchy-list',
    '/pim/menu/:menuId/revisions/:revisionId/hierarchies'
  ),
  'revision-hierarchy-create': new Route(
    'revision-hierarchy-create',
    '/pim/menu/:menuId/revisions/:revisionId/hierarchies/create'
  ),
  'revision-hierarchy-edit': new Route(
    'revision-hierarchy-edit',
    '/pim/menu/:menuId/revisions/:revisionId/hierarchies/:hierarchyId/edit'
  ),
  'revision-hierarchy-linked-categories': new Route(
    'revision-hierarchy-linked-categories',
    '/pim/menu/:menuId/revisions/:revisionId/hierarchies/:hierarchyId/linked-categories'
  ),

  // Categories

  'revision-category-list': new Route(
    'revision-category-list',
    '/pim/menu/:menuId/revisions/:revisionId/categories'
  ),
  'revision-category-create': new Route(
    'revision-category-create',
    '/pim/menu/:menuId/revisions/:revisionId/categories/create'
  ),
  'revision-category-edit': new Route(
    'revision-category-edit',
    '/pim/menu/:menuId/revisions/:revisionId/categories/:categoryId/edit'
  ),
  'revision-category-linked-categoryitems': new Route(
    'revision-category-linked-categoryitems',
    '/pim/menu/:menuId/revisions/:revisionId/categories/:categoryId/linked-categoryitems'
  ),
  'revision-category-used-by-hierarchies': new Route(
    'revision-category-used-by-hierarchies',
    '/pim/menu/:menuId/revisions/:revisionId/categories/:categoryId/used-by-hierarchies'
  ),
  'revision-category-used-by-categories': new Route(
    'revision-category-used-by-categories',
    '/pim/menu/:menuId/revisions/:revisionId/categories/:categoryId/used-by-categories'
  ),

  // Suggestions

  'revision-suggestion-list': new Route(
    'revision-suggestion-list',
    '/pim/menu/:menuId/revisions/:revisionId/suggestions'
  ),
  'revision-suggestion-create': new Route(
    'revision-suggestion-create',
    '/pim/menu/:menuId/revisions/:revisionId/suggestions/create'
  ),
  'revision-suggestion-edit': new Route(
    'revision-suggestion-edit',
    '/pim/menu/:menuId/revisions/:revisionId/suggestions/:suggestionId/edit'
  ),

  // Medias

  'revision-media-list': new Route(
    'revision-media-list',
    '/pim/menu/:menuId/revisions/:revisionId/medias'
  ),
  'revision-media-create': new Route(
    'revision-media-create',
    '/pim/menu/:menuId/revisions/:revisionId/medias/create'
  ),
  'revision-media-edit': new Route(
    'revision-media-edit',
    '/pim/menu/:menuId/revisions/:revisionId/medias/:mediaKey/edit'
  ),

  // Tags

  'revision-tag-list': new Route(
    'revision-tag-list',
    '/pim/menu/:menuId/revisions/:revisionId/tags'
  ),
  'revision-tag-create': new Route(
    'revision-tag-create',
    '/pim/menu/:menuId/revisions/:revisionId/tags/create'
  ),
  'revision-tag-edit': new Route(
    'revision-tag-edit',
    '/pim/menu/:menuId/revisions/:revisionId/tags/:tagKey/edit'
  ),

  // CustomData

  'revision-custom-data-list': new Route(
    'revision-custom-data-list',
    '/pim/menu/:menuId/revisions/:revisionId/custom-data'
  ),
  'revision-custom-data-create': new Route(
    'revision-custom-data-create',
    '/pim/menu/:menuId/revisions/:revisionId/custom-data/create'
  ),
  'revision-custom-data-edit': new Route(
    'revision-custom-data-edit',
    '/pim/menu/:menuId/revisions/:revisionId/custom-data/:customDataKey/edit'
  ),

  // CustomTexts

  'revision-custom-text-list': new Route(
    'revision-custom-text-list',
    '/pim/menu/:menuId/revisions/:revisionId/custom-text'
  ),
  'revision-custom-text-create': new Route(
    'revision-custom-text-create',
    '/pim/menu/:menuId/revisions/:revisionId/custom-text/create'
  ),
  'revision-custom-text-edit': new Route(
    'revision-custom-text-edit',
    '/pim/menu/:menuId/revisions/:revisionId/custom-text/:customTextKey/edit'
  ),

  // Sale Channels

  'revision-sale-channel-list': new Route(
    'revision-sale-channel-list',
    '/pim/menu/:menuId/revisions/:revisionId/sale-channels'
  ),
  'revision-sale-channel-create': new Route(
    'revision-sale-channel-create',
    '/pim/menu/:menuId/revisions/:revisionId/sale-channels/create'
  ),
  'revision-sale-channel-edit': new Route(
    'revision-sale-channel-edit',
    '/pim/menu/:menuId/revisions/:revisionId/sale-channels/:saleChannelId/edit'
  ),

  // Level configurations

  'revision-level-configuration-list': new Route(
    'revision-level-configuration-list',
    '/pim/menu/:menuId/revisions/:revisionId/level-configurations'
  ),
  'revision-level-configuration-create': new Route(
    'revision-level-configuration-create',
    '/pim/menu/:menuId/revisions/:revisionId/level-configurations/create/withParent/:parentLevelConfigurationPimId'
  ),
  'revision-level-configuration-edit': new Route(
    'revision-level-configuration-edit',
    '/pim/menu/:menuId/revisions/:revisionId/level-configurations/:levelConfigurationId/edit/withParent/:parentLevelConfigurationPimId'
  ),

  // Store informations

  'revision-store-information-table': new Route(
    'revision-store-information-table',
    '/pim/menu/:menuId/revisions/:revisionId/store-informations'
  ),

  // catalog-generation
  'menu-generation-list': new Route('menu-generation-list', '/pim/menu/:menuId/generations'),
  'revision-generation-list': new Route(
    'revision-generation-list',
    '/pim/menu/:menuId/revisions/:revisionId/generations'
  ),
  'catalog-generation-detail': new Route(
    'catalog-generation-detail',
    '/pim/menu/:menuId/revisions/:revisionId/catalog-generation/:catalogGenerationId'
  ),
  'catalog-generation-logs': new Route(
    'catalog-generation-logs',
    '/pim/menu/:menuId/revisions/:revisionId/catalog-generation/:catalogGenerationId/logs'
  ),

  // catalog-configuration
  'catalog-configuration-create': new Route(
    'catalog-configuration-create',
    '/pim/menu/:menuId/revisions/:revisionId/catalog-configuration/create'
  ),
  'catalog-configuration-edit': new Route(
    'catalog-configuration-edit',
    '/pim/menu/:menuId/revisions/:revisionId/catalog-configuration/:catalogConfigurationId/edit'
  ),

  // catalog
  'catalog-detail': new Route(
    'catalog-detail',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId'
  ),
  'catalog-category-list': new Route(
    'catalog-category-list',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/categories'
  ),
  'catalog-category-detail': new Route(
    'catalog-category-detail',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/category/:categoryId'
  ),
  'catalog-category-linked-categoryitems': new Route(
    'catalog-category-linked-categoryitems',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/category/:categoryId/categoryitems'
  ),
  'catalog-item-list': new Route(
    'catalog-item-list',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/items'
  ),
  'catalog-item-detail': new Route(
    'catalog-item-detail',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/item/:itemId'
  ),
  'catalog-item-linked-parts': new Route(
    'catalog-item-linked-parts',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/item/:itemId/parts'
  ),
  'catalog-item-store-configurations': new Route(
    'catalog-item-store-configurations',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/item/:itemId/store-configurations/:parentItemPartId'
  ),
  'catalog-item-part-list': new Route(
    'catalog-item-part-list',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/item-parts'
  ),
  'catalog-item-part-detail': new Route(
    'catalog-item-part-detail',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/item-part/:itemPartId'
  ),
  'catalog-item-part-linked-items': new Route(
    'catalog-item-part-linked-items',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/item-part/:itemPartId/items'
  ),
  'catalog-item-part-store-configurations': new Route(
    'catalog-item-part-store-configurations',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/item-part/:itemPartId/store-configurations/:parentItemId'
  ),
  'catalog-suggestion-list': new Route(
    'catalog-suggestion-list',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/suggestions'
  ),
  'catalog-suggestion-detail': new Route(
    'catalog-suggestion-detail',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/suggestion/:suggestionId'
  ),

  // catalog-deployment
  'catalog-deployment-start': new Route(
    'catalog-deployment-start',
    '/pim/menu/:menuId/revisions/:revisionId/catalog/:catalogId/start'
  ),
  'catalog-deployment-detail': new Route(
    'catalog-deployment-detail',
    '/pim/menu/:menuId/revision/:revisionId/catalog/:catalogId/deployment/:deploymentId'
  ),
  'catalog-deployment-store-detail': new Route(
    'catalog-deployment-store-detail',
    '/pim/menu/:menuId/revision/:revisionId/catalog/:catalogId/deployment/:deploymentId/store/:storeDeploymentId'
  ),
}

export function createPimSidebarItems() {
  return [
    {
      title: tr('sidebar.menu.pim.menu_list'),
      icon: null,
      route: getRoute('menu-list'),
      routeParams: {},
      display: Security.assert(PimPermissions.DATA_ACCESS),
    },

    {
      title: tr('sidebar.menu.pim.settings'),
      icon: null,
      route: getRoute('referential-settings-edit'),
      routeParams: {},
      display: Security.assert(PimPermissions.SETTINGS_ACCESS),
    },
  ]
}

export default function ({ createAsyncPage }) {
  return [
    // SETTINGS

    {
      definition: routesPimv2Definitions['referential-settings-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "referential-settings-edit" */ '../pages/pim/referential-settings-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.SETTINGS_ACCESS],
        menuReferer: routesPimv2Definitions['referential-settings-edit'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.settings'),
            definition: null,
          },
        ],
      },
    },

    // Menus

    {
      definition: routesPimv2Definitions['menu-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-list" */ '../pages/pim/menu-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-create'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-create" */ '../pages/pim/menu-create')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-create'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-create'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-detail'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-detail" */ '../pages/pim/menu-detail')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: null,
          },
        ],
      },
    },

    // Revisions

    {
      definition: routesPimv2Definitions['menu-revision-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-revision-list" */ '../pages/pim/menu-revision-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-revision-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-detail'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "revision-detail" */ '../pages/pim/revision-detail')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-revision-list'),
            definition: routesPimv2Definitions['menu-revision-list'],
          },
          {
            title: '{revisionName}',
            definition: null,
          },
        ],
      },
    },

    // ITEMS

    {
      definition: routesPimv2Definitions['revision-item-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "revision-item-list" */ '../pages/pim/revision-item-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-edit'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "revision-item-edit" */ '../pages/pim/revision-item-edit')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-list'),
            definition: routesPimv2Definitions['revision-item-list'],
          },
          {
            title: tr('ariane.pim.revision-item-edit'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-linked-parts'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-linked-parts" */ '../pages/pim/revision-item-linked-parts'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-list'),
            definition: routesPimv2Definitions['revision-item-list'],
          },
          {
            title: tr('ariane.pim.revision-item-edit'),
            definition: routesPimv2Definitions['revision-item-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-used-by-parts'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-used-by-parts" */ '../pages/pim/revision-item-used-by-parts'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-list'),
            definition: routesPimv2Definitions['revision-item-list'],
          },
          {
            title: tr('ariane.pim.revision-item-edit'),
            definition: routesPimv2Definitions['revision-item-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-used-by-categories'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-used-by-categories" */ '../pages/pim/revision-item-used-by-categories'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-list'),
            definition: routesPimv2Definitions['revision-item-list'],
          },
          {
            title: tr('ariane.pim.revision-item-edit'),
            definition: routesPimv2Definitions['revision-item-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-level-configuration'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-level-configuration" */ '../pages/pim/revision-item-level-configuration'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-list'),
            definition: routesPimv2Definitions['revision-item-list'],
          },
          {
            title: tr('ariane.pim.revision-item-edit'),
            definition: routesPimv2Definitions['revision-item-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-create'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "revision-item-create" */ '../pages/pim/revision-item-create')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-list'),
            definition: routesPimv2Definitions['revision-item-list'],
          },
        ],
      },
    },

    // ITEM PARTS

    {
      definition: routesPimv2Definitions['revision-item-part-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-part-list" */ '../pages/pim/revision-item-part-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-part-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-part-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-part-edit" */ '../pages/pim/revision-item-part-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-part-list'),
            definition: routesPimv2Definitions['revision-item-part-list'],
          },
          {
            title: tr('ariane.pim.revision-item-part-edit'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-part-linked-items'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-part-linked-items" */ '../pages/pim/revision-item-part-linked-items'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-part-list'),
            definition: routesPimv2Definitions['revision-item-part-list'],
          },
          {
            title: tr('ariane.pim.revision-item-part-edit'),
            definition: routesPimv2Definitions['revision-item-part-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-part-used-by-items'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-part-used-by-items" */ '../pages/pim/revision-item-part-used-by-items'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-part-list'),
            definition: routesPimv2Definitions['revision-item-part-list'],
          },
          {
            title: tr('ariane.pim.revision-item-part-edit'),
            definition: routesPimv2Definitions['revision-item-part-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-part-level-configuration'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-part-level-configuration" */ '../pages/pim/revision-item-part-level-configuration'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-part-list'),
            definition: routesPimv2Definitions['revision-item-part-list'],
          },
          {
            title: tr('ariane.pim.revision-item-part-edit'),
            definition: routesPimv2Definitions['revision-item-part-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-item-part-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-item-part-create" */ '../pages/pim/revision-item-part-create'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-item-part-list'),
            definition: routesPimv2Definitions['revision-item-part-list'],
          },
        ],
      },
    },

    // HIERARCHIES

    {
      definition: routesPimv2Definitions['revision-hierarchy-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-hierarchy-list" */ '../pages/pim/revision-hierarchy-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-hierarchy-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-hierarchy-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-hierarchy-edit" */ '../pages/pim/revision-hierarchy-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-hierarchy-list'),
            definition: routesPimv2Definitions['revision-hierarchy-list'],
          },
          {
            title: '{hierarchyName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-hierarchy-linked-categories'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-hierarchy-linked-categories" */ '../pages/pim/revision-hierarchy-linked-categories'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-hierarchy-list'),
            definition: routesPimv2Definitions['revision-hierarchy-list'],
          },
          {
            title: '{hierarchyName}',
            definition: routesPimv2Definitions['revision-hierarchy-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-hierarchy-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-hierarchy-create" */ '../pages/pim/revision-hierarchy-create'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-hierarchy-list'),
            definition: routesPimv2Definitions['revision-hierarchy-list'],
          },
        ],
      },
    },

    // CATEGORIES

    {
      definition: routesPimv2Definitions['revision-category-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-category-list" */ '../pages/pim/revision-category-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-category-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-category-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-category-edit" */ '../pages/pim/revision-category-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-category-list'),
            definition: routesPimv2Definitions['revision-category-list'],
          },
          {
            title: '{categoryName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-category-linked-categoryitems'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-category-linked-categoryitems" */ '../pages/pim/revision-category-linked-categoryitems'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-category-list'),
            definition: routesPimv2Definitions['revision-category-list'],
          },
          {
            title: '{categoryName}',
            definition: routesPimv2Definitions['revision-category-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-category-used-by-hierarchies'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-category-used-by-hierarchies" */ '../pages/pim/revision-category-used-by-hierarchies'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-category-list'),
            definition: routesPimv2Definitions['revision-category-list'],
          },
          {
            title: '{categoryName}',
            definition: routesPimv2Definitions['revision-category-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-category-used-by-categories'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-category-used-by-categories" */ '../pages/pim/revision-category-used-by-categories'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-category-list'),
            definition: routesPimv2Definitions['revision-category-list'],
          },
          {
            title: '{categoryName}',
            definition: routesPimv2Definitions['revision-category-edit'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-category-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-category-create" */ '../pages/pim/revision-category-create'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-category-list'),
            definition: routesPimv2Definitions['revision-category-list'],
          },
        ],
      },
    },

    // SUGGESTIONS

    {
      definition: routesPimv2Definitions['revision-suggestion-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-suggestion-list" */ '../pages/pim/revision-suggestion-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-suggestion-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-suggestion-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-suggestion-edit" */ '../pages/pim/revision-suggestion-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-suggestion-list'),
            definition: routesPimv2Definitions['revision-suggestion-list'],
          },
          {
            title: '{suggestionName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-suggestion-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-suggestion-create" */ '../pages/pim/revision-suggestion-create'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-suggestion-list'),
            definition: routesPimv2Definitions['revision-suggestion-list'],
          },
        ],
      },
    },

    // MEDIAS

    {
      definition: routesPimv2Definitions['revision-media-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "revision-media-list" */ '../pages/pim/revision-media-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-media-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-media-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-media-edit" */ '../pages/pim/revision-media-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-media-list'),
            definition: routesPimv2Definitions['revision-media-list'],
          },
          {
            title: '{mediaName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-media-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-media-create" */ '../pages/pim/revision-media-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-media-list'),
            definition: routesPimv2Definitions['revision-media-list'],
          },
        ],
      },
    },

    // TAGS

    {
      definition: routesPimv2Definitions['revision-tag-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "revision-tag-list" */ '../pages/pim/revision-tag-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-tag-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-tag-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-tag-edit" */ '../pages/pim/revision-tag-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-tag-list'),
            definition: routesPimv2Definitions['revision-tag-list'],
          },
          {
            title: '{tagName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-tag-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-tag-create" */ '../pages/pim/revision-tag-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-tag-list'),
            definition: routesPimv2Definitions['revision-tag-list'],
          },
        ],
      },
    },

    // CustomData

    {
      definition: routesPimv2Definitions['revision-custom-data-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-custom-data-list" */ '../pages/pim/revision-custom-data-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-custom-data-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-custom-data-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-custom-data-edit" */ '../pages/pim/revision-custom-data-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-custom-data-list'),
            definition: routesPimv2Definitions['revision-custom-data-list'],
          },
          {
            title: '{customDataName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-custom-data-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-custom-data-create" */ '../pages/pim/revision-custom-data-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-custom-data-list'),
            definition: routesPimv2Definitions['revision-custom-data-list'],
          },
        ],
      },
    },

    // CustomText

    {
      definition: routesPimv2Definitions['revision-custom-text-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-custom-text-list" */ '../pages/pim/revision-custom-text-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-custom-text-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-custom-text-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-custom-text-edit" */ '../pages/pim/revision-custom-text-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-custom-text-list'),
            definition: routesPimv2Definitions['revision-custom-text-list'],
          },
          {
            title: '{customTextName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-custom-text-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-custom-text-create" */ '../pages/pim/revision-custom-text-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-custom-text-list'),
            definition: routesPimv2Definitions['revision-custom-text-list'],
          },
        ],
      },
    },

    // SALE CHANNELS

    {
      definition: routesPimv2Definitions['revision-sale-channel-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-sale-channel-list" */ '../pages/pim/revision-sale-channel-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-sale-channel-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-sale-channel-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-sale-channel-edit" */ '../pages/pim/revision-sale-channel-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-sale-channel-list'),
            definition: routesPimv2Definitions['revision-sale-channel-list'],
          },
          {
            title: '{saleChannelName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-sale-channel-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-sale-channel-create" */ '../pages/pim/revision-sale-channel-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-sale-channel-list'),
            definition: routesPimv2Definitions['revision-sale-channel-list'],
          },
        ],
      },
    },

    // LEVEL CONFIGURATIONS

    {
      definition: routesPimv2Definitions['revision-level-configuration-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-level-configuration-list" */ '../pages/pim/revision-level-configuration-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-level-configuration-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-level-configuration-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-level-configuration-edit" */ '../pages/pim/revision-level-configuration-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-level-configuration-list'),
            definition: routesPimv2Definitions['revision-level-configuration-list'],
          },
          {
            title: '{levelConfigurationName}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-level-configuration-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-level-configuration-create" */ '../pages/pim/revision-level-configuration-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-level-configuration-list'),
            definition: routesPimv2Definitions['revision-level-configuration-list'],
          },
        ],
      },
    },

    // STORE INFORMATIONS

    {
      definition: routesPimv2Definitions['revision-store-information-table'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-store-information-table" */ '../pages/pim/revision-store-information-table'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.revision-store-information-table'),
            definition: null,
          },
        ],
      },
    },

    // SYNCHRONIZATIONS

    {
      definition: routesPimv2Definitions['menu-sync-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-sync-list" */ '../pages/pim/menu-sync-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-detail'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-sync-detail" */ '../pages/pim/menu-sync-detail')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
          {
            title: '{syncStartDate}',
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-step-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "menu-sync-step-detail" */ '../pages/pim/menu-sync-step-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-start'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-sync-start" */ '../pages/pim/menu-sync-start')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-workflow-run-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "menu-sync-workflow-run-list" */ '../pages/pim/menu-sync-workflow-run-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-run-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-workflow-run-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "menu-sync-workflow-run-detail" */ '../pages/pim/menu-sync-workflow-run-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-run-list'),
            definition: routesPimv2Definitions['menu-sync-workflow-run-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-run-detail'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-workflow-configuration-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "menu-sync-workflow-configuration-list" */ '../pages/pim/menu-sync-workflow-configuration-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-run-list'),
            definition: routesPimv2Definitions['menu-sync-workflow-run-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-configuration-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-workflow-configuration-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "menu-sync-workflow-configuration-create" */ '../pages/pim/menu-sync-workflow-configuration-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-run-list'),
            definition: routesPimv2Definitions['menu-sync-workflow-run-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-configuration-list'),
            definition: routesPimv2Definitions['menu-sync-workflow-configuration-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-configuration-create'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['menu-sync-workflow-configuration-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "menu-sync-workflow-configuration-edit" */ '../pages/pim/menu-sync-workflow-configuration-create-and-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.DATA_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-sync-list'),
            definition: routesPimv2Definitions['menu-sync-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-run-list'),
            definition: routesPimv2Definitions['menu-sync-workflow-run-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-configuration-list'),
            definition: routesPimv2Definitions['menu-sync-workflow-configuration-list'],
          },
          {
            title: tr('ariane.pim.menu-sync-workflow-configuration-edit'),
            definition: null,
          },
        ],
      },
    },

    // DEPLOYMENTS

    {
      definition: routesPimv2Definitions['menu-deployment-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-deployment-list" */ '../pages/pim/menu-deployment-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-deployment-list'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['revision-deployment-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-deployment-list" */ '../pages/pim/revision-deployment-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-revision-list'),
            definition: routesPimv2Definitions['menu-revision-list'],
          },
          {
            title: '{revisionName}',
            definition: routesPimv2Definitions['revision-detail'],
          },
          {
            title: tr('ariane.pim.revision-deployment-list'),
            definition: null,
          },
        ],
      },
    },

    // Catalog generation
    {
      definition: routesPimv2Definitions['menu-generation-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "menu-generation-list" */ '../pages/pim/menu-generation-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-generation-list'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['revision-generation-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "revision-generation-list" */ '../pages/pim/revision-generation-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-revision-list'),
            definition: routesPimv2Definitions['menu-revision-list'],
          },
          {
            title: '{revisionName}',
            definition: routesPimv2Definitions['revision-detail'],
          },
          {
            title: tr('ariane.pim.menu-generation-list'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-generation-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-generation-detail" */ '../pages/pim/catalog-generation-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-generation-list'),
            definition: routesPimv2Definitions['menu-generation-list'],
          },
          {
            title: tr('ariane.pim.catalog-generation-detail'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-generation-logs'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-generation-logs" */ '../pages/pim/catalog-generation-logs'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-generation-list'),
            definition: routesPimv2Definitions['menu-generation-list'],
          },
          {
            title: tr('ariane.pim.catalog-generation-detail'),
            definition: routesPimv2Definitions['catalog-generation-detail'],
          },
          {
            title: tr('ariane.pim.catalog-generation-logs'),
            definition: null,
          },
        ],
      },
    },

    // catalog-configuration
    {
      definition: routesPimv2Definitions['catalog-configuration-create'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-configuration-create" */ '../pages/pim/catalog-configuration-create'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-configuration-create'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-configuration-edit'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-configuration-edit" */ '../pages/pim/catalog-configuration-edit'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-configuration-edit'),
            definition: null,
          },
        ],
      },
    },

    // Catalog
    {
      definition: routesPimv2Definitions['catalog-detail'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "catalog-detail" */ '../pages/pim/catalog-detail')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-category-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-category-list" */ '../pages/pim/catalog-category-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-category-list'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-category-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-category-detail" */ '../pages/pim/catalog-category-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-category-list'),
            definition: routesPimv2Definitions['catalog-category-list'],
          },
          {
            title: '{categoryName}',
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-category-linked-categoryitems'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-category-linked-categoryitems" */ '../pages/pim/catalog-category-linked-categoryitems'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-category-list'),
            definition: routesPimv2Definitions['catalog-category-list'],
          },
          {
            title: '{categoryName}',
            definition: routesPimv2Definitions['catalog-category-detail'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['catalog-item-list'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "catalog-item-list" */ '../pages/pim/catalog-item-list')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-list'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-item-detail'],
      handler: createAsyncPage(() =>
        import(/*  webpackChunkName: "catalog-item-detail" */ '../pages/pim/catalog-item-detail')
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-list'),
            definition: routesPimv2Definitions['catalog-item-list'],
          },
          {
            title: '{itemName}',
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-item-linked-parts'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-item-linked-parts" */ '../pages/pim/catalog-item-linked-parts'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-list'),
            definition: routesPimv2Definitions['catalog-item-list'],
          },
          {
            title: '{itemName}',
            definition: routesPimv2Definitions['catalog-item-detail'],
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-item-store-configurations'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-item-store-configurations" */ '../pages/pim/catalog-item-store-configurations'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-list'),
            definition: routesPimv2Definitions['catalog-item-list'],
          },
          {
            title: '{itemName}',
            definition: routesPimv2Definitions['catalog-item-detail'],
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['catalog-item-part-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-item-part-list" */ '../pages/pim/catalog-item-part-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-part-list'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-item-part-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-item-part-detail" */ '../pages/pim/catalog-item-part-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-part-list'),
            definition: routesPimv2Definitions['catalog-item-part-list'],
          },
          {
            title: '{itemPartName}',
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-item-part-linked-items'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-item-part-linked-items" */ '../pages/pim/catalog-item-part-linked-items'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-part-list'),
            definition: routesPimv2Definitions['catalog-item-part-list'],
          },
          {
            title: '{itemPartName}',
            definition: routesPimv2Definitions['catalog-item-part-detail'],
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-item-part-store-configurations'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-item-part-store-configurations" */ '../pages/pim/catalog-item-part-store-configurations'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-item-part-list'),
            definition: routesPimv2Definitions['catalog-item-part-list'],
          },
          {
            title: '{itemPartName}',
            definition: routesPimv2Definitions['catalog-item-part-detail'],
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-suggestion-list'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-suggestion-list" */ '../pages/pim/catalog-suggestion-list'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-suggestion-list'),
            definition: null,
          },
        ],
      },
    },
    {
      definition: routesPimv2Definitions['catalog-suggestion-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-suggestion-detail" */ '../pages/pim/catalog-suggestion-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-suggestion-list'),
            definition: routesPimv2Definitions['catalog-suggestion-list'],
          },
          {
            title: '{suggestionName}',
            definition: null,
          },
        ],
      },
    },

    // catalog-deployment
    {
      definition: routesPimv2Definitions['catalog-deployment-start'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-deployment-start" */ '../pages/pim/catalog-deployment-start'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_EDIT],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.catalog-detail.with_name'),
            definition: routesPimv2Definitions['catalog-detail'],
          },
          {
            title: tr('ariane.pim.catalog-deployment-start'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['catalog-deployment-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-deployment-detail" */ '../pages/pim/catalog-deployment-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-deployment-list'),
            definition: routesPimv2Definitions['menu-deployment-list'],
          },
          {
            title: tr('ariane.pim.catalog-deployment-detail'),
            definition: null,
          },
        ],
      },
    },

    {
      definition: routesPimv2Definitions['catalog-deployment-store-detail'],
      handler: createAsyncPage(() =>
        import(
          /*  webpackChunkName: "catalog-deployment-store-detail" */ '../pages/pim/catalog-deployment-store-detail'
        )
      ),
      props: {
        withV2Design: true,
        permissions: [PimPermissions.CATALOG_ACCESS],
        menuReferer: routesPimv2Definitions['menu-list'],
        authRequired: true,
        icon: null,
        ariane: () => [
          {
            title: tr('ariane.pim'),
            definition: null,
          },
          {
            title: tr('ariane.pim.menu-list'),
            definition: routesPimv2Definitions['menu-list'],
          },
          {
            title: tr('ariane.pim.menu-detail'),
            definition: routesPimv2Definitions['menu-detail'],
          },
          {
            title: tr('ariane.pim.menu-deployment-list'),
            definition: routesPimv2Definitions['menu-deployment-list'],
          },
          {
            title: tr('ariane.pim.catalog-deployment-detail'),
            definition: routesPimv2Definitions['catalog-deployment-detail'],
          },
          {
            title: tr('ariane.pim.catalog-deployment-store-detail'),
            definition: null,
          },
        ],
      },
    },
  ]
}
