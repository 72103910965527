import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import isArray from 'lodash/isArray'
import isUndefined from 'lodash/isUndefined'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import invariant from 'invariant'

import { tr } from 'pmt-modules/i18n'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import TableContainer from '@material-ui/core/TableContainer'
import Tooltip from '@material-ui/core/Tooltip'

import { withStyles } from 'pmt-ui/styles'
import { Checkbox } from 'pmt-ui/Checkbox'
import DragAndDropSortContainer from 'pmt-ui/DragAndDrop/DragAndDropSortContainer'
import LoadingBlock, { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import {
  default as Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableFooter,
} from 'pmt-ui/Table'

import Pagination from './Pagination'

const styles = theme => ({
  table: {
    position: 'relative',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover .tableCellHover': {
      textDecoration: 'underline',
    },
  },
  tableRowDisabled: {
    textDecoration: 'line-through',
    background: theme.pmt.status.inactive,
    '&:hover': {
      background: `${theme.pmt.status.inactive}!important`,
    },
  },
  link: {
    display: 'block',
    lineHeight: '14px',
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  tableCellNotFound: {
    textAlign: 'center',
  },
  tableCellFooter: {
    textAlign: 'center',
    position: 'relative',
  },
  progress: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  headTooltip: {
    fontSize: 14,
    marginLeft: theme.spacing(0.5),
  },
})

const getTableCellAlign = (config, index) => {
  if (config.columns && config.columns[index]) {
    if (config.columns[index].alignRight) {
      return 'right'
    }
    if (config.columns[index].alignCenter) {
      return 'center'
    }
    if (config.columns[index].alignLeft) {
      return 'left'
    }
    return config.columns[index].numeric ? 'right' : 'left'
  }

  return config.numeric ? 'right' : 'left'
}

class TableHeadClassic extends React.Component {
  render() {
    const { classes, styles, tableCellConfig, table } = this.props

    return (
      <TableHead>
        <TableRow classes={{ root: styles.tableHeadRow }}>
          {table.tableHead.columns.map((headCell, index) => (
            <TableCell
              key={index}
              classes={{
                root: clsx(styles.tableCell, styles.tableHeadCell, styles[`tableHeadCell${index}`]),
              }}
              align={getTableCellAlign(tableCellConfig, index)}
            >
              {headCell.label || headCell}

              {table.tableHead.tooltips?.[index] && (
                <Tooltip title={table.tableHead.tooltips?.[index]} placement="bottom">
                  <InfoOutlinedIcon color="primary" className={classes.headTooltip} />
                </Tooltip>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

class TableHeadSort extends React.Component {
  createSortHandler = (tableIndex, tableMode, onRequestSort) => event => {
    onRequestSort(tableMode, tableIndex)
  }

  render() {
    const { styles, table, tableCellConfig, tableMode, tableIndex, onRequestSort } = this.props
    return (
      <TableHead>
        <TableRow classes={{ root: styles.tableHeadRow }}>
          {table.tableHead.columns.map((headCell, index) => {
            invariant(!isNil(headCell.index), 'TableHeadSort headCell index required')
            return (
              <TableCell
                key={headCell.index}
                classes={{
                  root: clsx(
                    styles.tableCell,
                    styles.tableHeadCell,
                    styles[`tableHeadCell${index}`]
                  ),
                }}
                align={getTableCellAlign(tableCellConfig, index)}
              >
                <TableSortLabel
                  active={tableIndex === headCell.index}
                  direction={tableMode}
                  onClick={this.createSortHandler(headCell.index, tableMode, onRequestSort)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }
}

const TableBodyContent = ({
  classes,
  datas,
  endDrag,
  onClickCell,
  moveCard,
  onSelect,
  retrieveDatas,
  selected,
  sortRows,
  styles,
  tableCellConfig,
  tableRowConfig,
  to,
}) =>
  datas?.map((data, indexRow) => {
    const retrievedDataData = retrieveDatas(data, indexRow)

    // allows `retrieveDatas` prop func to return an object with more configuration
    // - data: array
    // - rowClassName
    //
    // Otherwise, it is directly the 'data' array
    let retrievedData
    let options = {}
    if (isArray(retrievedDataData) || isNil(retrievedDataData)) {
      retrievedData = retrievedDataData
    } else {
      const { data, ...otherOptions } = retrievedDataData
      retrievedData = data
      options = otherOptions
    }

    const children = !isEmpty(retrievedData) && (
      <TableRow
        // trying to find an index using id or externalId for the pim.
        // using index is not recommended and can lead to invalid tables.
        key={data?.id || data?.externalId || data?.pimId || indexRow}
        className={clsx(
          classes.tableRow,
          styles.tableRow,
          tableRowConfig[`tableRow${indexRow}`] && tableRowConfig[`tableRow${indexRow}`].className,
          options.rowClassName,
          {
            [classes.tableRowDisabled]: options.tableRowDisabled,
            selected: selected && selected(data),
          }
        )}
        hover={tableRowConfig.hover}
        selected={
          (tableRowConfig[`tableRow${indexRow}`] &&
            tableRowConfig[`tableRow${indexRow}`].selected) ||
          false
        }
        onClick={() => onSelect && onSelect(data)}
        // add data to props to simplify debug when using react dev tools
        data={data}
      >
        {selected && (
          <TableCell width={48} padding="checkbox">
            <Checkbox
              checked={selected(data)}
              color="primary"
              onClick={() => onClickCell && onClickCell(0, data)}
            />
          </TableCell>
        )}
        {retrievedData.map((dataSelected, indexCell) => (
          <TableCell
            key={indexCell}
            classes={{
              root: clsx(
                styles.tableCell,
                styles[`tableCell${indexCell}`],
                tableRowConfig[`tableRow${indexRow}Cell${indexCell}`] &&
                  tableRowConfig[`tableRow${indexRow}Cell${indexCell}`].className,
                indexCell === 0 && !isUndefined(to) && 'tableCellHover'
              ),
            }}
            align={getTableCellAlign(tableCellConfig, indexCell + (selected ? 1 : 0))}
            padding={tableCellConfig.padding ? tableCellConfig.padding : 'default'}
            onClick={() => onClickCell && onClickCell(selected ? indexCell + 1 : indexCell, data)}
            {...tableCellConfig.props || {}}
          >
            {indexCell === 0 && !isUndefined(to)
              ? to(data, dataSelected, classes.link)
              : isFunction(dataSelected)
                ? dataSelected()
                : dataSelected}
          </TableCell>
        ))}
      </TableRow>
    )

    if (sortRows) {
      return (
        <DragAndDropSortContainer.CardForTable
          key={data?.id || data?.externalId || data?.pimId || indexRow}
          moveCard={moveCard}
          index={indexRow}
          item={data?.id || ''}
          endDrag={endDrag}
        >
          {children}
        </DragAndDropSortContainer.CardForTable>
      )
    }

    return children
  })

const defaultTableConfig = {
  tableHead: {
    columns: [],
  },
}

class TableGenerator extends React.Component {
  constructor(props) {
    super(props)

    // verify config
    const { config } = props

    // eslint-disable-next-line quotes
    invariant(!isNil(config.styles), `'styles' is required on config`)
    // eslint-disable-next-line quotes
    invariant(!isNil(config.table), `'table' is required on config`)
  }

  render() {
    const {
      datas,
      retrieveDatas,
      to,
      config,
      tableMode,
      tableIndex,
      isFetchingDatas,
      onRequestSort,
      classes,
      pagination,
      onLoadMore,
      endDrag,
      selected,
      onSelect,
      onClickCell,
      moveCard,
      size = 'medium',
      sortRows = false,
    } = this.props

    const { styles, table: givenTableConfig } = config

    // spread default configuration of the table with the given config
    const table = {
      ...defaultTableConfig,
      ...(isFunction(givenTableConfig) ? givenTableConfig() : givenTableConfig),
    }

    const tableCellConfig = {
      numeric: false,
      ...table.tableCell,
    }

    const tableRowConfig = {
      hover: true,
      ...table.tableRow,
    }

    const tableContainerProps = {
      // square: true,
      ...this.props.tableContainerProps,
    }

    return (
      <TableContainer {...tableContainerProps}>
        <Table className={clsx(classes.table, styles.table)} size={size}>
          {table.tableHead &&
          !isUndefined(table.tableHead.columns) &&
          table.tableHead.sortOptions ? (
            <TableHeadSort
              tableCellConfig={tableCellConfig}
              styles={styles}
              table={table}
              tableMode={tableMode}
              tableIndex={tableIndex}
              onRequestSort={onRequestSort}
            />
          ) : (
            table.tableHead &&
            !isUndefined(table.tableHead.columns) && (
              <TableHeadClassic
                classes={classes}
                tableCellConfig={tableCellConfig}
                styles={styles}
                table={table}
              />
            )
          )}
          <TableBody>
            {isFetchingDatas &&
              !isEmpty(datas) && (
                <tr>
                  <td>
                    <LoadingBlockWrapper show />
                  </td>
                </tr>
              )}
            {!isEmpty(datas) && (
              <TableBodyContent
                classes={classes}
                datas={datas}
                retrieveDatas={retrieveDatas}
                to={to}
                moveCard={moveCard}
                selected={selected}
                onSelect={onSelect}
                endDrag={endDrag}
                onClickCell={onClickCell}
                sortRows={sortRows}
                styles={styles}
                tableCellConfig={tableCellConfig}
                tableRowConfig={tableRowConfig}
              />
            )}
            {!isFetchingDatas &&
              isEmpty(datas) && (
                <TableRow>
                  <TableCell
                    className={classes.tableCellNotFound}
                    colSpan={table.tableHead ? table.tableHead.columns.length : undefined}
                  >
                    {tr('global.table.no_data_found')}
                  </TableCell>
                </TableRow>
              )}
            {isFetchingDatas &&
              isEmpty(datas) && (
                <TableRow>
                  <TableCell colSpan={table.tableHead ? table.tableHead.columns.length : undefined}>
                    <LoadingBlock classes={{ loadingBlockContainer: classes.progress }} show />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          <TableFooter>
            {!isNil(pagination) && (
              <Pagination
                isFetchingDatas={isFetchingDatas}
                pagination={pagination}
                classes={classes}
                table={table}
                onLoadMore={onLoadMore}
              />
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }
}

TableGenerator.defaultProps = {}

TableGenerator.propTypes = {
  datas: PropTypes.array,

  /**
   * Either:
   * - a function that will return an array of cell content
   * - an object with:
   *  - data: the function that will return an array of cell content
   *  - rowClassName: the className for the current row
   */
  retrieveDatas: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,

  /**
   * Configuration of the table.
   * TODO: more doc in the following:
   *
   * Divide in two parts:
   * - styles (classes)
   *    - tableHeadRow
   *    - tableHeadCell
   *    - tableHeadCellX
   * - table: can be a function or directly the object
   *    - tableHead
   *       - sortOptions (boolean)
   *       - columns (array): contains the head labels
   *    - tableRow
   *       - hover (boolean)
   *    - tableCell
   *          { columns: [null, { numeric: true }, { alignLeft: true }, { alignRight: true }], }
   */
  config: PropTypes.object.isRequired,
  onSort: PropTypes.func,

  // TODO: comment props
  // tableType: ...,
  // isFetchingDatas

  // to

  /**
   * function (object) => boolean
   * function that returns true if the given object has to be marked has selected
   *
   * If present, we add a cell with a checkbox.
   */
  selected: PropTypes.func,

  /**
   * Called when a cell is clicked on.
   * function (cellIndex, admissionFeeRule)
   *
   * Note: the cellIndex begin to 0. It correspond to the data index (+ 1 if in selected mode)
   */
  onClickCell: PropTypes.func,
  // onLoadMore
  // pagination
}
export default withStyles(styles)(TableGenerator)
